<template>
  <div id="formulario-edit-info">    
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row">
            <div class="vx-col w-full">                                    
                <vs-button class="mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>                                             
            </div>
        </div>

        <div class="vx-row mt-2">
            <div class="vx-col w-1/3"><label class="vs-input--label">Marca</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Tipo</label></div>              
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3">
                <v-select                                               
                    :options="CONSTANTE_MARCA_MODELOS_MODULOS" 
                    v-model="data_local.marca"    
                    :clearable="false"
                    :reduce="r => r.data"   
                    :disabled="registroOriginal.hasOwnProperty('codigo')"   
                    @input="alterarModelo"              
                    class="w-full vs-input--label">
                </v-select> 
            </div>     
            <div class="vx-col w-1/3">
                    <v-select                                               
                        :options="getModeloModulo" 
                        v-model="data_local.tipo"    
                        :clearable="false"
                        :reduce="r => r.data"                    
                        class="w-full vs-input--label">
                    </v-select> 
            </div>                  
        </div>       
               
        <div class="vx-row mt-2">
            <div class="vx-col w-full">
                <label class="vs-input--label mr-2">Dados CSV separados por vírgula ( codigo, serial, numero gsm, operadora gsm, observacao )</label>
                <vs-button class="ml-4 mt-2" size="small" color="primary" @click="converterCSV" :disabled="!dadosCSV || dadosCSV.length ==0">Converter CSV</vs-button>                             
                
            </div>            
        </div>   
        <div class="vx-row mt-4">
            <div class="vx-col w-full">
                <vs-textarea v-model="dadosCSV" />
            </div>
        </div>
        <div class="vx-row mt-4">
            <div class="vx-col flex w-full">
                <vs-button size="small" @click="gravarTodos" v-show="isAllowedToSave && qtdModuloParaInserir > 0" color="success">INSERIR MÓDULOS</vs-button>         
                <h4 class="ml-4" v-if="dadosMontados && dadosMontados.length"> {{ qtdModuloParaInserir }} de {{dadosMontados.length}} módulos a inserir</h4>
            </div>
        </div>        

        <div class="vx-row mt-2" > 
            <div class="vx-col w-1/6"> <label class="vs-input--label mr-2">Código </label></div>
            <div class="vx-col w-1/6"> <label class="vs-input--label mr-2">Serial </label> </div>
            <div class="vx-col w-1/6"> <label class="vs-input--label mr-2">Numero GSM </label> </div>
            <div class="vx-col w-1/6"> <label class="vs-input--label mr-2">Operadora GSM </label> </div>
            <div class="vx-col w-1/6"> <label class="vs-input--label mr-2">Observação </label> </div>
            <div class="vx-col w-1/6"> </div>
        </div>
        <div class="vx-row" v-for="(modulo,indice) in  dadosMontados" :key="indice"> 
            <div class="vx-col w-1/6">                
                <vs-input class="w-full" v-model="modulo[0]" :maxlength="60" />
            </div>
            <div class="vx-col w-1/6">
                <vs-input class="w-full" v-model="modulo[1]" :maxlength="60" />
            </div>
            <div class="vx-col w-1/6">
                <vs-input class="w-full" v-model="modulo[2]" :maxlength="60" />
            </div>
            <div class="vx-col w-1/6">                
                <v-select                                               
                    :options="CONSTANTE_OPERADORAS_GSM" 
                    :reduce="r => r.data"                    
                    v-model="modulo[3]"                      
                    class="w-full vs-input--label">
                </v-select>  
            </div>
            <div class="vx-col w-1/6">
                <vs-input class="w-full" v-model="modulo[4]" :maxlength="60" />
            </div>
            <div class="vx-col w-1/6">
                <vx-tooltip :text="modulo[5]" v-if="modulo[5] && modulo[5].toLowerCase().indexOf('erro') !== -1">                
                    <vs-icon icon="error" size="small" class="mt-1" color="danger"></vs-icon>
                </vx-tooltip>
                <vx-tooltip :text="modulo[5]" v-if="modulo[5] && modulo[5].toLowerCase().indexOf('erro') === -1">                
                    <vs-icon icon="pause" size="small" class="mt-1" color="primary"></vs-icon>
                </vx-tooltip>
                <vx-tooltip text="Gravado com sucesso." v-if="!modulo[5]">                
                    <vs-icon icon="done" size="small" class="mt-1" color="success" v-if="!modulo[5]"></vs-icon>
                </vx-tooltip>
            </div>
        </div>
        
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"



import { CONSTANTE_STATE_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_MARCA_MODELOS_MODULOS } from '@/constantesComboBox.js'
import { CONSTANTE_OPERADORAS_GSM } from '@/constantesComboBox.js'

const URL_SERVLET = "/ModulosRest";
const NAME_ROUTE_SEARCH = "cadastro-modulos-pesquisar";

export default {   
  components: {

  } ,
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {marca: null, serial: null, tipo: null, numeroGSM: null, operadoraGSM: null, numero2GSM: null, operadora2GSM: null, stateModulo: 'EM ESTOQUE', idClienteProprietario: null,
            idTecnico: null, idClienteComPosse: null, observacao: null}
       }
    },
  },
  data() {
    return {            
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),        
        dadosCSV : null,
        dadosMontados : [],
        posicaoCorrente : 0
    }
  },  
  created(){
      this.CONSTANTE_MARCA_MODELOS_MODULOS = CONSTANTE_MARCA_MODELOS_MODULOS;
      this.CONSTANTE_STATE_MODULO = CONSTANTE_STATE_MODULO;
      this.CONSTANTE_OPERADORAS_GSM = CONSTANTE_OPERADORAS_GSM;
  }, 
  mounted() {
    
  },
  computed: {            
    isAllowedToSave() {
       let perfilLogado = this.$store.state.AppActiveUser.perfil;
       if (perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {
            return false;	
       } 

       return true;
    },
    getModeloModulo() {
        if (this.data_local.marca) {
            let marca =  this.CONSTANTE_MARCA_MODELOS_MODULOS.find( ({data}) => data === this.data_local.marca);
            if (marca) {
                return marca.modelos;
            }
        }

        return [];
    },
    qtdModuloParaInserir(){
        if (!this.dadosMontados || this.dadosMontados.length === 0) {
            return 0;
        }
        
        let resultPendentes = this.dadosMontados.filter(modulo => modulo[5] && modulo[5].toLowerCase().indexOf('pendente') != -1 );

        if (resultPendentes) {
            return resultPendentes.length;
        }

        return 0;

    },
    qtdModuloComErro(){
        if (!this.dadosMontados || this.dadosMontados.length === 0) {
            return 0;
        }

        let resultComErro = this.dadosMontados.filter(modulo => modulo[5] && modulo[5].toLowerCase().indexOf('erro') != -1 );
        

        if (resultComErro) {
            return resultComErro.length;
        }

        return 0;

    }
  },
  methods: {      
    alterarModelo() {
        this.data_local.tipo = null;
    },   
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];

        if (!this.data_local.marca) {
            this.errorsValidacao.push("Marca obrigatório");
        }

        if (!this.data_local.tipo) {
            this.errorsValidacao.push("Tipo obrigatório");
        }  

        if (this.qtdModuloParaInserir < 1) {
            this.errorsValidacao.push("Nenhum módulo para inserir.");
        } 

        if (!this.errorsValidacao.length) {
            return true;
        }   
        
      return false;
    },
    gravarTodos() {  
        this.posicaoCorrente = 0;      
        if (this.formularioValido()) { 
            this.$vs.loading();                          
            this.gravarProximo()                    
        }
    },
    gravarProximo(){
        
        if (this.posicaoCorrente <= this.dadosMontados.length -1 ) {
            if (this.validarLinhaModulo(this.dadosMontados[this.posicaoCorrente])) {
                this.gravar(this.dadosMontados[this.posicaoCorrente]);
            } else {
                this.posicaoCorrente++;
                this.gravarProximo();
            }
        } else {
            this.$vs.loading.close();
        }
    },
    gravar(modulo) {        
        if (this.formularioValido()) {             
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.$store.state.AppActiveUser.id);
            params.append('method', 'Insert');      

            params.append('marca', this.data_local.marca);      
            params.append('tipo', this.data_local.tipo);
            params.append('stateModulo', "EM ESTOQUE");     

            params.append('codigo', modulo[0]);               
            params.append('serial', modulo[1]);   
            
            if (modulo[3] && modulo[3] === "SAT") {
                modulo[2] = modulo[0];
            }

            if (modulo[2]) {  params.append('numeroGSM', modulo[2]); }
            if (modulo[3]) {  params.append('operadoraGSM', modulo[3]); }            
            if (modulo[4]) {  params.append('observacao', modulo[4]); }
            
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {                
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                    
                    if (response.data.hasOwnProperty("error")) {                        
                        this.$set(modulo, 5, "Erro:" + response.data.error);
                    } else {
                        this.$set(modulo, 5, null);
                    }      
                    console.log(this.posicaoCorrente + " = " + modulo[5]);     
                    this.posicaoCorrente++;                    
                    this.gravarProximo();
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    validarLinhaModulo(modulo) {
        let erros = "";
        
        if (!modulo) { 
            modulo = [];
            while (modulo.length < 5)  {
                modulo.push("");
            }
            modulo[5] = "Erro: Linha invalida";
            return;
        } else if (modulo.length < 5) {
            while (modulo.length < 5)  {
                modulo.push("");
            }
            modulo[5] = "Erro: Linha invalida";
            return;
        }

        if (!modulo[0]) {
            erros = "Erro: Código obrigatório \n";
        }


        if (!modulo[1]) {
            erros  = erros + "Erro: Serial obrigatório\n";
        }

        //Validar operadora
        if (modulo[2] && this.CONSTANTE_OPERADORAS_GSM.findIndex((operadora) => operadora.data == modulo[3]) === -1 ) {            
            this.$set(modulo, 3, null);
            erros  = erros + "Erro: OperadoraGSM inválida\n";
        }

        if (modulo[3] && modulo[3] !== "SAT" && modulo[2] && modulo[2].length < 10) {
            erros  = erros + "Erro: Informe o numero do simcard com o DDD.";
        }

        if (modulo[3] && modulo[3] !== "SAT" && !modulo[2]) {
            erros  = erros + "Erro: Informe o numero do simcard com o DDD.";
        }
        
        while (modulo.length < 6)  {
            modulo.push("");
        }

        if (!erros) {
            erros = "Pendente de gravação."
        }

        this.$set(modulo, 5, erros);

        return ( erros.indexOf('Erro') === -1 ) ;
    },
    converterCSV()  {
        this.dadosMontados = [];
        let linhas = this.dadosCSV.split("\n");

        if (!linhas || linhas.length == 0 ) {
            this.errorsValidacao.push("Nenhum módulo para importar.");		
            return;
        }

        let dados = null;

        for (let i = 0; i < linhas.length; i++) {
           dados = linhas[i].split(",");           
           this.validarLinhaModulo(dados);
           this.dadosMontados.push(dados);
        }

    }
  }
}
</script>